<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item >强算子解读</el-breadcrumb-item>
        <el-breadcrumb-item>解读详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-card shadow="always" class="detail-container">
      <div>
        <div class="title">强算子内容</div>
        <div style="padding: 20px">
          <div v-for="item in content" :key="item.key" style="display: flex; align-items: baseline; line-height: 52px">
            <div class="item-key">{{ item.key }}</div>
            <div class="item-value">{{ item.val }}</div>
          </div>
        </div>

        <div class="title">强算子解读</div>
        <div style="padding: 20px">
          <div v-for="item in analysis" :key="item.key" style="display: flex; align-items: baseline; line-height: 52px">
            <div style="width: 100px">{{ item.key }}</div>
            <div style="width: 500px; line-height: 22px; word-break: break-all" v-if="
                item.key !== '影响主题' &&
                item.key !== '影响行业' &&
                item.key !== '影响个股' &&
                item.key !== '影响大盘'
              ">
              {{ item.val }}
            </div>
            <div v-else>
              <div v-if="item.val.length == 0">无</div>
              <div v-else>
                <div v-for="(item, index) in item.val" :key="index" style="display: flex; line-height: 32px">
                  <div style="width: 160px">{{ item.subject }}</div>
                  <div style="margin-left: 40px">{{ item.degree }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="btn-group">
          <div class="btn edit" @click="edit">编辑</div>
          <div class="btn" @click="back">返回</div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue";
import { formatterDate } from "@/utils/format";
import { useRouter, useRoute } from "vue-router";
export default {
  setup(props, content) {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      content: [],
      analysis: [],
    });

    onMounted(() => {
      //获取详情
      getDetail();
    });

    const edit = () => {
      router.push({ path: "/content/edit" });
    };

    const back = () => {
      router.push({ path: "/content/identifyAreas/interpretationStrongOperators" });
    };

    const getDetail = () => {
      const id = route.query.id;
      proxy
        .$post("/cts/back/policy/detail", { id })
        .then((result) => {
          if (result.resultState == "200") {
            const { data } = result;
            localStorage.setItem("detailData", JSON.stringify(data));
            const { content, releaseDate, title, originalLink } = data;
            const {
              interpretationTime,
              interpretationMan,
              policyInterpretation,
              influenceList,
            } = data;
            let influenceArr = [],
              influenceSubjectArr = [],
              influenceStock = [],
              influenceDaPanArr = [];
            influenceList.forEach((item) => {
              if (item.influenceType == "1") {
                influenceArr.push({
                  subject: item.name,
                  degree: item.influenceLevelName,
                });
              } else if (item.influenceType == "2") {
                influenceSubjectArr.push({
                  subject: item.name,
                  degree: item.influenceLevelName,
                });
              } else if (item.influenceType == "3") {
                influenceStock.push({
                  subject: item.name,
                  degree: item.influenceLevelName,
                });
              } else if (item.influenceType == "4") {
                influenceDaPanArr.push({
                  subject: item.name,
                  degree: item.influenceLevelName
                })
              }
            });
            state.content = [
              {
                key: "发布日期",
                val: formatterDate(releaseDate),
              },
              {
                key: "强算子标题",
                val: title,
              },
              {
                key: "强算子内容",
                val: content,
              },
              {
                key: "原文链接",
                val: originalLink,
              },
            ];
            state.analysis = [
              {
                key: "解读时间",
                val: interpretationTime,
              },
              {
                key: "解读人",
                val: interpretationMan,
              },
              {
                key: "强算子解读",
                val: policyInterpretation,
              },
              {
                key: "影响大盘",
                val: influenceDaPanArr,
              },
              {
                key: "影响行业",
                val: influenceArr,
              },
              {
                key: "影响主题",
                val: influenceSubjectArr,
              },
              {
                key: "影响个股",
                val: influenceStock,
              },
            ];
          }
        })
        .catch((err) => { });
    };

    return {
      ...toRefs(state),
      edit,
      back,
    };
  },
};
</script>

<style lang="scss" scoped>
.detail-container {
  .title {
    font-weight: bold;
  }
  .item-key {
    width: 100px;
  }
  .item-value {
    width: 500px;
    word-break: break-all;
    line-height: 22px;
  }
  .btn-group {
    display: flex;
    .btn {
      width: 80px;
      border: 1px solid #dcdcdc;
      text-align: center;
      height: 30px;
      line-height: 30px;
      border-radius: 5px;
      cursor: pointer;
      &.edit {
        background: #38a28a;
        color: #fff;
        margin-right: 15px;
      }
    }
  }
}
</style>
